import { createReducer, on } from '@ngrx/store';
import { GeoRegion } from 'src/app/modules/core/types/geo-area';
import { PlantColor } from 'src/app/modules/core/types/plant-color';
import { UserRegion } from 'src/app/types/user-location';
import { AppActions } from './actions';
import { getSelectedRegion, simplePointDistance } from 'src/app/helpers/location';
import { GeoPos, GeoPosSnapShot } from 'src/app/types/geo-pos';
import { round } from 'lodash-es';
import { SiteModes } from 'src/app/modules/core/types/app-features';
import { environment } from 'src/environments/environment';
import { CronActions } from '../cron/cron.actions';

// round location
const LOCATION_PRECISION = 5;

export interface AppState {
  manualLocation: UserRegion;
  geopos: GeoPos;
  geoposStatus: string;
  geoposAccuracy: number,
  today: Date;
  colors: Array<PlantColor>;
  locations: Array<GeoRegion>;
  locationUpdates: number;
  locationUpdateFails: number;
  geoposHistory: GeoPosSnapShot[];
  slowGeopos: GeoPos;
  hostname: string;
  siteSetting: SiteModes | null;
  locale: string;
  plantnames: {
    [key: string]: { latin, local }[]
  };
  newVersionAvailable: boolean;
}

export const initialApplicationState: AppState = {
  today: new Date(),
  geopos: null,
  geoposStatus: 'no-support',
  geoposAccuracy: null,
  manualLocation: null,
  colors: [],
  locations: [],
  locationUpdates: 0,
  locationUpdateFails: 0,
  geoposHistory: [],
  slowGeopos: null,
  hostname: '',
  siteSetting: null,
  locale: 'en',
  plantnames: {},
  newVersionAvailable: false
};

// Only update slow location when position is that far apart
const SLOW_LOCATION_UPDATE_BUFFER_METERS = 2500;

export const ApplicationReducer = createReducer(
  initialApplicationState,

  // Browser geoposition update received
  on(AppActions.geolocationUpdated, (state, { position }) => {
    // Normalize the update
    const geoposUpdate = {
      latitude: round(position.coords.latitude, LOCATION_PRECISION),
      longitude: round(position.coords.longitude, LOCATION_PRECISION),
      accuracy: position.coords.accuracy || 10,   // Provide a default accuracy, should it be missing
    };

    return {
      ...state,
      geopos: {
        ...geoposUpdate
      },
      geoposStatus: '',

      // Optional update of slow geopos
      // Only update when too far apart
      // NOTE: slowGeopos could also use history for "improved geops calculation", but
      //    here we are jsut setting the browser location directly
      slowGeopos: !state.slowGeopos || simplePointDistance(geoposUpdate, state.slowGeopos) > SLOW_LOCATION_UPDATE_BUFFER_METERS ? {
        ...geoposUpdate
      } : state.slowGeopos,

      // Keeping track of the updates / numnber of updates
      locationUpdates: state.locationUpdates + 1,
      geoposHistory: [{
        ...geoposUpdate,
        ts: new Date(),
      } as GeoPosSnapShot].concat(state.geoposHistory).slice(0, 100)
    }
  }),
  on(AppActions.geolocationUpdateFail, (state, { error }) => ({
    ...state,
    geopos: null,
    geoposStatus: error.message,
    locationUpdateFails: state.locationUpdateFails + 1
  })),
  // When cron daily triggers, set today to current date
  // NOTE: maybe cleaner to do via an extra effect and trigger setTodayDate
  //  but this way it is simpler
  on(CronActions.daily, (state) => ({
    ...state,
    today: new Date(),
  })),
  on(AppActions.setTodayDate, (state, { date }) => ({
    ...state,
    today: date,
  })),
  on(AppActions.colorsLoaded, (state, { result }) => ({
    ...state,
    colors: result.results,
  })),
  on(AppActions.locationsLoaded, (state, { result }) => ({
    ...state,
    locations: result.results,
  })),

  // User selects a region (acts as an override to the auto/default region)
  on(AppActions.manualSelectLocation, (state, { locationId }) => ({
    ...state,
    manualLocation: getSelectedRegion(locationId, state.locations),
  })),

  // User geos back to automatic region selection
  on(AppActions.useAutoLocation, (state) => ({
    ...state,
    manualLocation: null,
  })),

  // Set the app locale
  on(AppActions.setLocale, (state, { locale }) => ({
    ...state,
    locale
  })),

  // Set the hostname (NOTE: environment.forceHostname always overrides - dev option)
  on(AppActions.setHostname, (state, { hostname }) => ({
    ...state,
    hostname: (typeof(environment.forceHostname) === "string" && environment.forceHostname !== "" ? environment.forceHostname : hostname),
  })),

  // Set site setting (when using an entry-link, see siteSettingRedirectGuard)
  on(AppActions.setSiteSetting, (state, { setting }) => ({
    ...state,
    siteSetting: SiteModes[setting]
  })),

  // Plantname translations have been fetched
  on(AppActions.plantNamesLoaded, (state, { locale, data }) => ({
    ...state,
    plantnames: {
      ...state.plantnames,
      [locale]: data
    }
  })),

  on(AppActions.newVersionAvailable, (state, { newVersionAvailable: versionIsAvailable }) => ({
    ...state,
    newVersionAvailable: versionIsAvailable
  }))
);
