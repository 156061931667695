import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './modules/core/services/locale.service';
import { DevelopmentService } from './modules/core/development.service';
import { environment } from 'src/environments/environment';
import { AppActions } from './state/app/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // NOTE: all content is hidden unless (translate) loaded is true!
  // That means if translate loader does not suceed, page will be fully blank...
  loaded = false;

  constructor(
    translate: TranslateService,
    localeService: LocaleService,
    development: DevelopmentService,
    private store: Store
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // TODO: reverse this - trigger an action (or init action), then the effect
    // sets the default language .. will consider localstorage, browserlang and hostname
    // the LocaleService will be a client to the effect
    const initLang = localeService.getInitalLanguage();
    translate.use(initLang).subscribe(() => {
      this.loaded = true;

      // TODO: does this create a second fetch ?
      // If an issue, create two actions: setLocale and setLocaleDone, where just the
      // first has an effect attached (which then maps to setLocaleDone)
      store.dispatch(AppActions.setLocale({locale: initLang}));
    });

    // Expose the store on the window object when run via cypress
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.store = this.store;
    }

    if (!environment.production) {
      development.startMocks();
    }
  }
}
