import { createSelector } from '@ngrx/store';
import { lightness } from 'src/app/helpers/colors';
import { State } from '../reducers';
import { AppState } from './reducers';
import { GeoPosSnapShot } from 'src/app/types/geo-pos';
import { round } from 'lodash-es';
import { getAdaptiveGeoposition, getRegionFromGeoPos, simplePointDistance } from 'src/app/helpers/location';
import { AuthSelectors } from '../auth/auth.selectors';
import { AppFeatures, SiteModes, appFeaturesDefault, defaultModes } from 'src/app/modules/core/types/app-features';
import { environment } from 'src/environments/environment';

export const appStateSelector = (state: State) => state.app;

export class AppSelectors {
  static appLocale = createSelector(appStateSelector, (state) => state.locale);
  static internalGeopos = createSelector(appStateSelector, (state: AppState) => state.geopos);
  static geoposStatus = createSelector(appStateSelector, (state: AppState) => state.geoposStatus);

  /**
   * List of defined regions (all, enabled, default region)
   */
  static locations = createSelector(appStateSelector, (state: AppState) => state.locations);
  static enabledLocations = createSelector(AppSelectors.locations, (locations) =>
    locations.filter((loc) => loc.enabled !== false)
  );
  static defaultLocation = createSelector(AppSelectors.enabledLocations, (locations) =>
    locations.find((loc) => loc.default)
  );

  /**
  * History of gps locations.
  */
  static geoposHistory = createSelector(appStateSelector, (state: AppState) => state.geoposHistory.reduce((prev, cur) => prev.concat([{
    ...cur,
    diffLat: prev.length ? round(prev[prev.length - 1].latitude - cur.latitude, 4) : null,
    diffLong: prev.length ? round(prev[prev.length - 1].longitude - cur.longitude, 4) : null,
    diffDist: prev.length ? simplePointDistance(prev[prev.length - 1], cur) : null
  } as GeoPosSnapShot]), []));

  /**
   * Mainly location debugging
   *
   * Show number of failed and sucessful updates and the history of gps locations.
   */
  static geoPosDebugInfo = createSelector(
    appStateSelector,
    AppSelectors.geoposHistory,
    (state: AppState, history: GeoPosSnapShot[]) => ({
      updates: state.locationUpdates,
      fails: state.locationUpdateFails,
      history,
      adaptive: getAdaptiveGeoposition(history),
    })
  );

  /**
   * Geoposition for the catalgue - updates slower
   */
  static slowGeoposition = createSelector(appStateSelector, (state) => state.slowGeopos);

  static manualLocation = createSelector(appStateSelector, (state) => state.manualLocation);
  /**
   * (Computed) geoposition.
   *
   * If available, use the history of gps measurements to come up with a (hopefully) more accurate position.
   */
  static improvedGeopos = createSelector(
    AppSelectors.internalGeopos,
    AppSelectors.geoposHistory,
    (geopos, history) => getAdaptiveGeoposition(history) || geopos);

  /**
   * The region that the user is in.
   * This affects all catalogue reads, prevalence, blooming times etc.
   * It localizes the cataloge to a region.
   * The region can come from three places: default region, automatic from gps, override by
   * manual selection.
   */
  static userRegion = createSelector(AppSelectors.manualLocation, AppSelectors.slowGeoposition, AppSelectors.locations, (manualLocation, geopos, locations) => {
    const fromGeo = getRegionFromGeoPos(geopos, locations);
    return manualLocation || fromGeo;
  });

  /**
     * List of defined pollen colors.
     */
  static colors = createSelector(appStateSelector, (state: AppState) =>
    state.colors.map((color) => ({ ...color, brightness: lightness(color.colorval) }))
  );

  /**
   * Today's date.
   */
  static today = createSelector(appStateSelector, (state: AppState) => state.today);

  /**
   * Hostname
   */
  static hostname = createSelector(appStateSelector, (state: AppState) => state.hostname);
  static settingByHost = createSelector(AppSelectors.hostname, (hostname) => hostname.startsWith("bee") ? 'betterb' : 'pollen');

  /**
   * Site setting
   */
  static siteSetting = createSelector(appStateSelector, AppSelectors.hostname, (state: AppState, hostname) => (
    state.siteSetting || ( hostname === 'beeplants.eu' ? 'betterb' : 'pollen' )
  ));

  /**
   * Features enabled for the current app
   *
   * Using defaults, config and the user profile.
   * NOTE / FIXME: refactor to use this whenever there are feature switches
   *               can replace: instances when auth/user is read directly, or even the feature map service
   */
  static appFeatures = createSelector(
    AppSelectors.hostname,
    AppSelectors.siteSetting,
    AuthSelectors.user,
    (hostname, siteSetting, user) => {
      return {
        ...appFeaturesDefault,
        ...user?.userprofile,

        // Convert flags into the "modes" map (NOTE: what is the advantage of this layout?)
        // Take modes flags either from user (if auth), or from the defaults (per site-setting)
        modes: user && user.userprofile ? {
          [SiteModes.betterb]: user.userprofile.featuresBeeCount,
          [SiteModes.pollen]: user.userprofile.featuresPollen
        } : { ...defaultModes[siteSetting || 'betterb'] },

        // Also including the "static" feature map from the environment files
        // prod / dev, and depending on staff status
        features: user && user.is_staff && !!environment.staffFeatures ?
          { ...environment.staffFeatures } : { ...environment.features },
        hostname,
        siteSetting,
        loggedIn: !!user,
      } as AppFeatures;
    }
  );

  static plantnamesDict = createSelector(appStateSelector, state => state.plantnames);
  static plantnames = createSelector(AppSelectors.plantnamesDict, AppSelectors.appLocale, (plantnames, locale) => plantnames[locale]);
  static newVersionAvailable = createSelector(appStateSelector, state => state.newVersionAvailable);
}
